@tailwind base;
@tailwind components;
@tailwind utilities;

:root,
body,
* {
  margin: 0;
  padding: 0;
  font-family: 'Raleway';
  font-weight: bolder;
}

body {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100svh;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100vw;
  height: 100vh;
}

.map_main {
  max-width: 150svh;
  max-height: 150svh;
  height: 150svh !important;
  object-fit: contain;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.intro_holder {
  width: 100svw;
  overflow: hidden;
  position: relative;
}

.intro_bg {
  height: 100svh;
  width: 100svw;
  object-position: 25%;
  filter: blur(12px);
  object-fit: cover;
}

.action_btn {
  width: 19.42svw;
  position: absolute;
  top: 21.67svw;
  left: 38.84svw;
  transition: all 1s;
}

.action_btn:active {
  transform: scale(1.2);
}

.map_holder {
  position: relative;
  height: 150svh;
  width: 100svw;
  overflow-y: hidden;
}

.platform {
  width: 7.5svh;
  object-fit: contain;
  position: absolute;
  animation-name: scaleUp;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  transform: scale(0);
}

.active {
  animation: scaleUpDown 2s infinite ease-in-out;
}

@media (max-width: 768px) {

  .action_btn {
    width: 40%;
    position: absolute;
    top: 45%;
    left: 30%;
    transition: all 1s;
  }
}

@keyframes scaleUpDown {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}



@keyframes breathing {
  0%, 100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(1.025);
  }
}

.breathing {
  animation: breathing 3s ease-in-out infinite;
  transform-origin: bottom;
}


@keyframes talking {
  0%, 100% {
    transform: scaleY(1);
  }
  5% {
    transform: scaleY(1.08);
  }
  15% {
    transform: scaleY(1.05);
  }
  25% {
    transform: scaleY(1.1);
  }
  35% {
    transform: scaleY(1.03);
  }
  45% {
    transform: scaleY(1.12);
  }
  55% {
    transform: scaleY(1.06);
  }
  65% {
    transform: scaleY(1.09);
  }
  75% {
    transform: scaleY(1.04);
  }
  85% {
    transform: scaleY(1.1);
  }
  95% {
    transform: scaleY(1.07);
  }
}

.talking {
  animation: talking 2s linear infinite;
  transform-origin: bottom;
}
